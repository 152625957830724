import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Container } from "react-bootstrap"
import PageTitle from "../components/PageTitle/PageTitle"
import ContentBlock from "../components/ContentBlock/ContentBlock"
import ProductContext from "../context/ProductContext"
import styled from "styled-components"
import ProductGrid from "../components/Products/ProductsGrid"

import queryString from "query-string"
import { useLocation } from "@reach/router"
import FeaturedProductTile from "../components/Products/FeaturedProductTile"

const Content = styled.div`
  @media (min-width: 768px) {
    display: flex;
    width: 100%;
    margin-top: 20px;
  }
`

const ProductsPage = () => {
  const { products, collections } = React.useContext(ProductContext)
  const collectionProductMap = {}
  const [featuredProduct, setFeaturedProduct] = React.useState([])
  const { search } = useLocation()
  const qs = queryString.parse(search)
  const selectedCollectionIds = qs.c?.split(",").filter(c => !!c) || []
  const selectedCollectionIdsMap = {}

  console.log('products', products)


  selectedCollectionIds.forEach(collectionId => {
    selectedCollectionIdsMap[collectionId] = true
  })

  if (collections) {
    collections.forEach(collection => {
      collectionProductMap[collection.shopifyId] = {}

      collection.products.forEach(product => {
        collectionProductMap[collection.shopifyId][product.shopifyId] = true
      })
    })
    collections.forEach(featured => {
      if (featured.title === "Featured") {
        featured.products.forEach(product => {
          featuredProduct.push(product)
        })
      }
      console.log(featuredProduct)
    })
  }

  //console.log(featuredProduct)

  const filterByCategory = product => {
    if (Object.keys(selectedCollectionIdsMap).length) {
      for (let key in selectedCollectionIdsMap) {
        if (collectionProductMap[key]?.[product.shopifyId]) {
          return true
        }
      }
      return false
    }
    return true
  }

  const filteredProducts = products.filter(filterByCategory)

  return (
    <Layout>
      <SEO title="Cart" />
      <Container fluid className="container-fluid-full">
        <PageTitle
          subPage="USS IDAHO Merchandise"
          pageTitle="USS IDAHO Merchandise"
          link="/"
        ></PageTitle>
        <ContentBlock>
          <Content>
            <div>
              {!!collections.find(
                collection => collection.title === "Featured"
              ) && (
                <div>
                  {featuredProduct[0].images[0].localFile && (
                    <FeaturedProductTile
                      title={featuredProduct[0].title}
                      minPrice={
                        featuredProduct[0].priceRangeV2.minVariantPrice.amount
                      }
                      handle={featuredProduct[0].handle}
                      image={featuredProduct[0].images[0]}
                      imageFluid={
                        featuredProduct[0].images[0].localFile?.childImageSharp
                          .fluid
                      }
                    />
                  )}
                </div>
              )}

              <ProductGrid products={filteredProducts} />
            </div>
          </Content>
        </ContentBlock>
      </Container>
    </Layout>
  )
}

export default ProductsPage

import React from 'react'
import styled from 'styled-components'
import Img from 'gatsby-image'
import { StyledLink} from '../StyledLink/StyledLink'

const ProductTileWrapper = styled.div`
    border: 1px solid #ddd;
    display: flex;
    flex-direction: column;
    border-radius: 5px;
    overflow: hidden;
    padding: 8px;
    width: 100%;
    margin: 0.5rem;

    @media (max-width: 768px) { 
        width: 100%;
    }

    > ${StyledLink} {
        color: white;
        text-align: center;
        padding-bottom: .3rem;

        &:hover {
            text-decoration: none;
        }
    }
`;

const Description = styled.div`
    color: #999;
    text-align: left;
`;

const Title = styled.div`
    font-weight: bold;
    font-size: 18px;
    margin: 20px;
    text-align: center;
`;

const Price = styled.div`
    font-style: italic;
    padding: 20px;
    font-weight: bold;
    margin-top: auto;
`;

const ProductTile = ({ title, imageFluid, description, minPrice, handle }) => {
    return (
        <ProductTileWrapper>
            <Img fluid={imageFluid} style={{height: '200px'}}/>
            <Title>
                {title}
            </Title>
            {/* <Description>
                {description}
             </Description> */}
             <Price>
                from ${parseFloat(minPrice).toFixed(2)}
             </Price>
             <StyledLink to={`/products/${handle}`} className="ussIdahoButton">View Product</StyledLink>
            
        </ProductTileWrapper>
    )
}

export default ProductTile
import React from 'react'
import styled from 'styled-components'
import Img from 'gatsby-image'
import { StyledLink} from '../StyledLink/StyledLink'
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

const ProductTileWrapper = styled.div`
    border: 1px solid #ddd;
    display: flex;
    flex-direction: row;
    border-radius: 5px;
    overflow: hidden;
    padding: 1rem;
    width: 100%;
    margin: 0.5rem;

    @media (max-width: 768px) { 
        width: 100%;
        flex-direction: column;
    }

    > div > ${StyledLink} {
        color: white;
        text-align: center;
        padding-bottom: .3rem;
        
        &:hover {
            text-decoration: none;
        }
    }
`;

const ImageWrapper = styled.div`
    width: 25%;

    @media (max-width: 768px) {
        width: 100%;
    }
`
const Description = styled.div`
    padding-left: 1rem;
    text-align: left;
`;

const Title = styled.div`
    font-weight: 700;
    font-size: 1.5rem;
    text-align: left;
`;

const Price = styled.div`
    font-style: italic;
    font-weight: bold;
    margin-top: auto;
    margin-bottom: 1rem;
`;

const FeaturedProductTile = ({ title, imageFluid, description, minPrice, handle, image }) => {
    console.log(image)
    return (
        <ProductTileWrapper>
            <ImageWrapper>
                <GatsbyImage image={getImage(image.localFile)} />
                {/* <Img fluid={imageFluid} style={{height: '100%'}}/> */}
            </ImageWrapper>
            <Description>
                <div>
                    <Title>
                        {title}
                    </Title>
                     <Price>
                        from ${parseFloat(minPrice).toFixed(2)}
                     </Price>
                </div>
                 <StyledLink to={`/products/${handle}`} className="ussIdahoButton">View Product</StyledLink>
            </Description>
            
        </ProductTileWrapper>
    )
}

export default FeaturedProductTile